import React, { useState } from 'react';
import Layout from '../components/layout/Layout';
import {Helmet} from "react-helmet";
import firebase from "gatsby-plugin-firebase"

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [msj, setMsj] = useState("");
    const [tlf, setTlf] = useState("");

    const clearForm = () => {
        setName("");
        setEmail("");
        setMsj("");
        setTlf("");
    }

    const handleSubmit = event => {
        event.preventDefault();
      
        const data = { email, message:msj,tlf:tlf, name:name, time: Date().toLocaleString() };
        var sendMail = firebase.functions().httpsCallable('submit');
        sendMail(data).then((result) => {
            // Read result of the Cloud Function.
            console.log("nice")
            firebase.firestore().collection('contacts').add({name:name,email:email,msj:msj,time:Date().toLocaleString()})
            clearForm();
          })
          .catch((error) => {
            console.log(error)
          });
      };
    
    return (
    <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Cantabria digital: El mejor diseño web de cantabria</title>
            <meta name="description" content="Paginas web hechas a mano y personalizadas, un diseño único que hara que tu negocio se diferencie del resto" />
            <html lang="es"/>
            <link rel="canonical" href="https://cantabriadigital.es" />
        </Helmet>
        <h1 className="font-bold text-lg text-center my-10">Contacta con nosotros</h1>
        <div className="flex justify-center">        
            <form className="mt-8 md:w-1/2 border-solid border-gray-300" onSubmit={handleSubmit} method="POST">
                <div className="flex flex-col justify-center">
                    <div className="flex mx-5 items-center">
                            <div className="mx-5 w-full">
                                <div className="text-gray-500 font-bold ">Nombre Completo</div>
                                <input name="nombre" value={name} onChange={e => setName(e.currentTarget.value)} className="appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="inline-full-name" type="text" />
                            </div>
                    </div>
                    <div className="flex flex-col md:flex-row mx-5 my-5">
                        <div className="text-left mx-5 w-full">
                            <div className="text-gray-500 text-left font-bold ">Email</div>
                            <input type="email" name="email" value={email} onChange={e => setEmail(e.currentTarget.value)} className="appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="inline-full-name" type="text" />
                        </div>
                        <div className="text-left mx-5 w-full">
                            <div className="text-gray-500 text-left font-bold">Telefono</div>
                            <input name="telefono" value={tlf} onChange={e => setTlf(e.currentTarget.value)}  className="appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-200" id="inline-full-name" type="text" />
                        </div>
                    </div>
                    <div className="text-left mx-10">
                            <div className="text-gray-500 text-left font-bold">Tu mensaje</div>
                            <textarea name="mensaje" value={msj} onChange={e => setMsj(e.currentTarget.value)}  className="resize h-full appearance-none border-2 border-gray-400 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-green-200" rows="10" id="inline-full-name" type="text" />
                        </div>
                    <button className="mx-10 my-5 shadow bg-green-600 hover:bg-green-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded" type="submit">
                        Enviar
                    </button>
                </div>
            </form>
        </div>
    </Layout>  
    )
}

export default ContactForm;